import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Handsontable from 'handsontable';

import {colFromKey, colKeysData_fix, colKeysDetail_fix, colKeysData_unfix, colKeysDetail_unfix, keyFromCol, keyInfoFromCol, colKeysNo_data, colKeysNo_detail} from "@/components/tokubai/input/TokubaiInputTableModel";
import { RequestParam } from "@/assets/apitype/tokubaiInputRegist";

import * as calcUtil from "@/util/calcUtil";
import * as editorUtil from "@/util/editorUtil";
import * as compareUtil from "@/util/compareUtil";
import {v4 as uuid} from 'uuid';
import {CodeName} from "@/store/common";
import { colDataType } from "@/components/tokubai/input/TokubaiInputTableModel";
import moment from 'moment';

export type RetrievedId = {uuid: string, lv:number};
//エラー情報
export interface ErrorInfoData {
  IJN?: string | null,
  ICD?: string | null,
}
//エラー情報
export interface ErrorInfoDetail {
  SCD?: string | null, SNM?: string | null,
  KFR?: string | null, KTO?: string | null,
  HPL?: string | null,
  OFS?: string | null,
  OND?: string | null,
  OON?: string | null,
  OOZ?: string | null,
  ONT?: string | null,
  OOU?: string | null,
  OLDA?: string | null,
  OLDB?: string | null,
}
export interface WarnInfoDetail {
  OON?: string | null,
  OOZ?: string | null,
  ZTN?: string | null,
  OFS?: string | null,
}

export interface RowData {
  seq?: number,
  uuid?: string,

  itemInfoRetrieve?: boolean,
  itemInfoRetrievedId?: string,
  itemInfos?: ItemInfo[],
  itemInfoIndex?: number,
  //以下に貼り付け
  IJN?: string | null, IJC?: number | null, ICD?: string | null, ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null, ICN?: string | null, IBC?: string | null, IBN?: string | null, ICP?: string | null, IC1?: number | null, IC2?: number | null, ILI?: number | null, IPR?: number | null, ISD?: string | null, IED?: string | null, ITN?: number | null, 

  //明細
  details? :RowDataDetail[],
  // SCD?: string | null, SNM?: string | null,
  // HPL?: number | null,
  // OFS?: string | null, OOH?: number | null, OON?: number | null, OOZ?: number | null, OFN?: number | null, OAN?: number | null, OMM?: string | null,

  //エラー情報
  errorInfo?:ErrorInfoData,
  //表示状態
  visible?: boolean,

  //編集
  edited?:boolean,
}
//明細
export interface RowDataDetail {
  seq?: number,
  uuid?: string,

  //定番マスタ
  teibanInfo?: TeibanInfo,

  //以下に貼り付け
  SKEY?: string | null,
  SCD?: string | null, SNM?: string | null, 
  OSCD?: string | null, OSNM?: string | null, 
  TCD?: string | null, TNM?: string | null, TDR?: boolean | null, TKN?: string | null,
  KFR?: string | null, KTO?: string | null,
  HPL?: number | null,
  OFS?: string | null, OND?: string | null, OOS?: boolean | null, OOU?: string | null, OON?: number | null, ONOA?: number | null, OOZ?: number | null, ONOB?: number | null, OFN?: number | null, OAN?: number | null, OLDA?: string | null, OLDB?: string | null, OLT?: number | null, OMM?: string | null, OME?: string | null, ONT?: string | null, OUC?: string | null, OUN?: string | null,
  RVM?: string | null,
  TBN?: string | null,
  ZTN?: string | null,
  ZQ1?: number | null, ZQ2?: number | null, ZQ3?: number | null,
  DATEUP?: string | null
  TIMEUP?: string | null

  //(A)or(B)
  OOH?: boolean | null,

  //エラー情報
  errorInfo?:ErrorInfoDetail,
  warnInfo?:WarnInfoDetail,
  //表示状態
  visible?: boolean,
  //編集
  edited?:boolean,
  delete_flg?: boolean,

  //登録時の判定用
  bakOON?: number | null,
}


//アイテム情報
export type ItemInfo = {
  // IJN: "JAN",
  IJN: string,
  // IJC: "同一JAN数",
  IJC: number,
  // ICD: "商品CD",
  ICD: string,
  // ISC: "仕入先CD",
  ISC: string,
  // ISN: "仕入先名",
  ISN: string,
  // IMC: "メーカーCD",
  IMC: string,
  // IMN: "メーカー名",
  IMN: string,
  // INM: "商品名",
  INM: string,
  // ICC: "カテゴリCD",
  ICC: string,
  // ICN: "カテゴリ名",
  ICN: string,
  // IBC: "PB区分CD",
  IBC: string,
  // IBN: "PB区分名",
  IBN: string,
  // ICP: "内容量",
  ICP: string,
  // IC1: "BL内入数",
  IC1: number,
  // IC2: "CS内入数",
  IC2: number,
  // ILI: "賞味期間",
  ILI: number,
  // IPR: "定価",
  IPR: number,
  //ISD: "発売日",
  ISD?: string,
  //IED: "終売日",
  IED?: string,
  //OOU: "入庫単位",
  OOU?: string,

  //定番マスタ
  teibanInfos: TeibanInfo[],
}
//定番マスタ情報
export type TeibanInfo = {
	SCD:string, //倉庫
	SNM:string, //倉庫
	ICD:string, //商品コード
	TCD1:string, //得意先本部ｺｰﾄﾞ
	HLOT:number, //発注単位:店出
  ISD:string,
  IED:string,
	TTAN:number, //特売原単価:店出
	TSLE:number, //売価:特売
	SDAT:number, //特売開始日付
	EDAT:number,  //特売終了日付
  ZTN?: string | null,
  ZQ1?: number | null,
  ZQ2?: number | null,
  ZQ3?: number | null,
  DATEUP?: string | null,
  TIMEUP?: string | null,
  OLT?: number | null,
  OUC?: string | null,
  OUN?: string | null,
  TBN?: string, //定番
}

export interface DuplicateRowData {
  CSQ?: number,
  STS?: string | null,
  ICD?: string | null,
  INM?: string | null,
  MRD?: string | null,
  MRU?: string | null,
  SCD?: string | null,
  SNM?: string | null,
  OSCD?: string | null,
  OSNM?: string | null,
  TCD?: string | null,
  TNM?: string | null,
  TDR?: boolean | null,
  KFR?: string | null,
  KTO?: string | null,
  OOS?: boolean | null,
  OFS?: string | null,
  OND?: string | null,
  OIN?: number | null,
  OMM?: string | null,
  OME?: string | null,
  ONT?: string | null,
  OLD?: string | null,
}

//未入力判定
export const isEmptyRowData = (data:RowData) => {
  return !data
    || (
      !data.uuid &&
      !data.ICD &&
      !data.IJN
    );
}
export const isEmptyRowDataDetail = (detail:RowDataDetail) => {
  const isEdit: boolean = detail
    && (
      detail.KFR ||
      detail.KTO ||
      detail.HPL ||
      detail.OFS ||
      detail.OND ||
      detail.OOS ||
      detail.OON ||
      detail.OOZ ||
      detail.OMM ||
      detail.OME ||
      detail.OLDA ||
      detail.OLDB
    ) ? true : false;
  if (detail?.uuid) {
    detail.delete_flg = !isEdit;
  }
  return !detail
    || (
      !(detail.uuid && !detail?.delete_flg) &&
      !detail.KFR &&
      !detail.KTO &&
      !detail.HPL &&
      !detail.OFS &&
      !detail.OND &&
      !detail.OOS &&
      !detail.OON &&
      !detail.OOZ &&
      !detail.OMM &&
      !detail.OME &&
      !detail.OLDA &&
      !detail.OLDB
      // !detail.ONT  //摘要のみは空白行とする
    );
}

export interface RowInfo {
  no?: string,
  row:number,
  data:RowData,
  dataIndex:number,
  detail:RowDataDetail,
  detailDataIndex:number,
  detailRowIndex:number,
  detailRowEnd:boolean,
}


export type findedCell = {
  row: number,
  col: number,
  data,
  text: string,
}

//Page State
export type State = {
  //企業グループ１
  group1List: CodeName[],
  //得意先支店
  tokuisakiSubList: CodeName[],
  kuraire5List: CodeName[],
  direct5List: CodeName[],

  editing: boolean,
  //編集中の条件
  editingParam : RequestParam,

  //保留中一覧
  suspends: CodeName[] | null,

  // //検索する条件
  // requestParam : RequestParam,
  // requestParamQueue : RequestParam[],

  //倉庫(コピー)
  centersIndex: number,
  centers: CodeName[] | null,

  //倉庫
  centerList: CodeName[],


  progress: Record<string, unknown>,
  datas: RowData[],
  // dataRowIndexes: number[],
  rowInfos: RowInfo[],
  rows,
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],
  // selectionRowStart: number,
  // selectionRowEnd: number,
  beforeChange: {changes: Handsontable.CellChange[], source:Handsontable.ChangeSource},

  initList: string[] | null,

  errorMessage: string | null,
  infoMessage: string | null,
};

export const initialState: State = {
  //企業グループ１
  group1List: [],
  //得意先支店
  tokuisakiSubList: [],
  kuraire5List: [],
  direct5List: [],

  editing: false,
  editingParam : {},

  //保留中一覧
  suspends: [],

  // requestParam : {},
  // requestParamQueue: [],

  //倉庫(コピー)
  centersIndex: 0,
  centers: [],
  //倉庫
  centerList: [],

  progress: {},
  datas: [],
  // dataRowIndexes: [],
  rowInfos: [],
  rows: [],
  mergeCells: [],
  // selectionRowStart: -1,
  // selectionRowEnd: -1,
  beforeChange: null,

  initList: ["group1"],

  errorMessage: null,
  infoMessage: null,
};

//商品属性をクリアする
const clearItemInfo = (data:RowData):RowData => {

  data.itemInfoRetrieve = false;
  data.itemInfoRetrievedId = null;
  data.itemInfos = [];
  data.itemInfoIndex = null;

  data.seq = null;
  data.uuid = null;
  data.IJN = null;
  data.IJC = null;
  data.ICD = null;
  data.ISC = null;
  data.ISN = null;
  data.IMC = null;
  data.IMN = null;
  data.INM = null;
  data.ICC = null;
  data.ICN = null;
  data.IBC = null;
  data.IBN = null;
  data.ICP = null;
  data.IC1 = null;
  data.IC2 = null;
  data.ILI = null;
  data.IPR = null;
  data.ISD = null;
  data.IED = null;
  data.ITN = null;
  //明細
  data.details = [];
  // data.SCD = null;
  // data.SNM = null;
  // data.HPL = null;
  // data.OFS = null;
  // data.OOH = null;
  // data.OON = null;
  // data.OOZ = null;
  // data.OFN = null;
  // data.OAN = null;
  // data.OMM = null;

  return data;
}
//商品属性をセットする
const setItemInfo = (data:RowData, infos:ItemInfo[]):RowData => {

  let itemInfoIndex = 0;
  //すでに選択済みの場合
  if(data.ICD) {
    infos.find((info, index) => {
      const b = info.ICD == data.ICD;
      if(b) {
        itemInfoIndex = index;
      }
      return b;
    });
  }

  const newData:RowData = {...data, ...{
    itemInfoRetrieve : false,
    itemInfos : infos,
    itemInfoIndex : itemInfoIndex,
    //商品属性
    IJN: infos.length == 0 ? data.IJN : infos[itemInfoIndex].IJN,
    IJC: infos.length,
    ICD: infos.length == 0 ? data.ICD : infos[itemInfoIndex].ICD,
    ISC: infos.length == 0 ? null : infos[itemInfoIndex].ISC,
    ISN: infos.length == 0 ? null : infos[itemInfoIndex].ISN,
    IMC: infos.length == 0 ? null : infos[itemInfoIndex].IMC,
    IMN: infos.length == 0 ? null : infos[itemInfoIndex].IMN,
    INM: infos.length == 0 ? null : infos[itemInfoIndex].INM,
    ICC: infos.length == 0 ? null : infos[itemInfoIndex].ICC,
    ICN: infos.length == 0 ? null : infos[itemInfoIndex].ICN,
    IBC: infos.length == 0 ? null : infos[itemInfoIndex].IBC,
    IBN: infos.length == 0 ? null : infos[itemInfoIndex].IBN,
    ICP: infos.length == 0 ? null : infos[itemInfoIndex].ICP,
    IC1: infos.length == 0 ? null : infos[itemInfoIndex].IC1,
    IC2: infos.length == 0 ? null : infos[itemInfoIndex].IC2,
    ILI: infos.length == 0 ? null : infos[itemInfoIndex].ILI,
    IPR: infos.length == 0 ? null : infos[itemInfoIndex].IPR,
    ISD: infos.length == 0 ? null : infos[itemInfoIndex].ISD,
    IED: infos.length == 0 ? null : infos[itemInfoIndex].IED,
  }};

  setTeibanInfo(newData);

  newData.edited = !isEmptyRowData(newData);

  return newData;
};

//定番マスタをセットする
const setTeibanInfo = (data:RowData) => {
  if(!data || !data.details) {
    return;
  }
  //初期化
  if(!(data.itemInfos && data.itemInfos.length > 0 && data.itemInfoIndex < data.itemInfos.length)) {
    data.details.forEach(detail => {
      setTeibanInfoDetail(detail, null);
    });
    return;
  }
  const itemInfo = data.itemInfos[data.itemInfoIndex];
  //初期化
  if(!itemInfo.teibanInfos || itemInfo.teibanInfos.length == 0) {
    data.details.forEach(detail => {
      setTeibanInfoDetail(detail, null);
    });
    return;
  }

  //センターが同一ならばセット
  data.details.forEach(detail => {
    setTeibanInfoDetail(detail, itemInfo.teibanInfos.find(teibanInfo => teibanInfo.SCD == detail.SCD), itemInfo, data);
  });
}
//定番マスタをセットする
const setTeibanInfoDetail = (detail: RowDataDetail, teibanInfo: TeibanInfo, itemInfo?: ItemInfo, data?: RowData) => {
  if(!detail) {
    return;
  }

  detail.teibanInfo = teibanInfo;
  //商品属性
  if (data && teibanInfo) {
    data.ITN = teibanInfo.HLOT;
    data.ISD = teibanInfo.ISD?.length == 8 && moment(teibanInfo.ISD).isValid() ? moment(teibanInfo.ISD).format('YY/MM/DD') : null;
    data.IED = teibanInfo.IED?.length == 8 && moment(teibanInfo.IED).isValid() ? moment(teibanInfo.IED).format('YY/MM/DD') : null;
  }
  /*
  if(!detail.KFR) {
    detail.KFR = teibanInfo && teibanInfo.SDAT > 20000000 ? moment(teibanInfo.SDAT).format('YY/MM/DD') : null;
  }
  if(!detail.KTO) {
    detail.KTO = teibanInfo && teibanInfo.EDAT > 20000000 ? moment(teibanInfo.EDAT).format('YY/MM/DD') : null;
  }
  */
  detail.RVM = detail.RVM !== undefined ? detail.RVM : '未受信';

  //在庫情報
  detail.TBN = teibanInfo ? teibanInfo.TBN : null;
  detail.ZTN = teibanInfo ? teibanInfo.ZTN : null;
  detail.ZQ1 = teibanInfo ? teibanInfo.ZQ1 : null;
  detail.ZQ2 = teibanInfo ? teibanInfo.ZQ2 : null;
  detail.ZQ3 = teibanInfo ? teibanInfo.ZQ3 : null;
  detail.DATEUP = teibanInfo ? teibanInfo.DATEUP : null;
  detail.TIMEUP = teibanInfo ? teibanInfo.TIMEUP : null;

  //発注情報
  if(!detail.OOU) {
    detail.OOU = !itemInfo ? '' : itemInfo.OOU == 'C' ? 'CS' : itemInfo.OOU == 'B' ? 'BL' : null;
  }
  detail.OLT = teibanInfo ? teibanInfo.OLT : null;
  detail.OUC = teibanInfo ? teibanInfo.OUC : null;
  detail.OUN = teibanInfo ? teibanInfo.OUN : null;
}


//Page Slice
export const tokubaiInputTmpSlice = createSlice({
  name: "tokubaiInputTmp",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount() {
      console.log('store.initOnDidMount');
    },
    //componentWillUnmount
    resetOnWillUnmount() {
      console.log('store.resetOnWillUnmount');
    },

    // Option Group1
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    // Option tokuisaki
    setTokuisakiSubList(state:State, action: PayloadAction<CodeName[]>) {
      state.tokuisakiSubList = action.payload;
    },
    setKuraire5List(state:State, action: PayloadAction<CodeName[]>) {
      state.kuraire5List = action.payload;
    },
    setDirect5List(state:State, action: PayloadAction<CodeName[]>) {
      state.direct5List = action.payload;
    },

    setEditingStart(state:State, action: PayloadAction<RequestParam>) {
      console.log('store.setEditingStart');
      Object.assign(state, {
        editing: true,
        editingParam: action.payload,
      });
    },
    setEditingEnd(state) {
      console.log('store.setEditingEnd');

      const centers = state.centers;
      const useTeibanAll = state.centersIndex == 0;

      const datas = [{}];
      const rowInfos = convertRows(datas, centers, useTeibanAll);
      Object.assign(state, {
        editing: false,
        editingParam: initialState.editingParam,

        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });

    },
    // //検索条件
    // setRequestParam(state:State, action: PayloadAction<RequestParam>) {
    //   console.log('store.setRequestParam');
    //   state.requestParam = action.payload;
    // },
    // addRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.addRequestParamQueue');
    //   state.requestParamQueue = [...state.requestParamQueue, ...action.payload];
    // },
    // setRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.setRequestParamQueue');
    //   state.requestParamQueue = action.payload;
    // },
    // clearRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.clearRequestParamQueue');
    //   state.requestParamQueue = [];
    // },

    setSuspends(state:State, action: PayloadAction<CodeName[]>) {
      console.log('store.setSuspends');
      state.suspends = action.payload;
    },
    putProgress(state:State, action: PayloadAction<string>) {
      console.log('store.putProgress');
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:State, action: PayloadAction<string>) {
      console.log('store.removeProgress');
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },

        // Option Center
    setCenters(state:State, action: PayloadAction<{centersIndex:number, centers:CodeName[]}>) {
      console.log('storeTmp.setCenters');
      const centersIndex = action.payload.centersIndex;
      const centers = action.payload.centers;
      const useTeibanAll = centersIndex == 0;
      let datas:RowData[] = [...state.datas];

      datas = fillDatasDetailsCenter(datas, centers, useTeibanAll); //センター情報を埋める
      datas = calcDatas(datas); //計算項目の計算
      datas = checkDatas(datas); //データチェック

      const rowInfos = convertRows(datas, centers, useTeibanAll);
      Object.assign(state, {
        centersIndex: centersIndex,
        centers: centers,

        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });

    },
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },

    addRow(state:State, action: PayloadAction<number>) {
      console.log('store.addRow');
      const rowCount = action.payload;

      let newDatas:RowData[] = [];
      for(let i=0 ; i<rowCount; i++) {
        const data:RowData = {};
        newDatas.push(data);
      }

      const useTeibanAll = state.centersIndex == 0;
      newDatas = fillDatasDetailsCenter(newDatas, state.centers, useTeibanAll); //センター情報を埋める
      newDatas = calcDatas(newDatas); //計算項目の計算
      newDatas = checkDatas(newDatas); //データチェック
      // newDatas = doSort(newDatas, sort.key, sort.asc, false);  //ソート

      const datas = [...state.datas, ...newDatas];
      // datas = resetRowNo(datas, null); //列番号の振りなおし

      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    insertRowDetails(state: State, action: PayloadAction<{ indexs: { dataIndex: number, detailDataIndex: number }[] }>) {
      const indexs = action.payload.indexs;
      console.log('store.insertRowDetails');

      indexs.sort((a, b) => {
        if (b.dataIndex == a.dataIndex) {
          return b.detailDataIndex - a.detailDataIndex;
        }
        return b.dataIndex - a.dataIndex;
      });
      indexs.forEach(index => {
        const dataIndex = index.dataIndex;
        const detailDataIndex = index.detailDataIndex;

        const motoData = state.datas[dataIndex].details[detailDataIndex];
        let detail: RowDataDetail = {
          ...motoData,
          teibanInfo: { ...motoData.teibanInfo },
          edited: true,
          seq: null,
          uuid: null,
        };
        detail = calcDetail(detail);
        detail = checkDetail(state.datas[dataIndex], detail);

        state.datas[dataIndex].details.splice(detailDataIndex + 1, 0, detail);
        state.datas[dataIndex].details = calcDetails(state.datas[dataIndex].details);
      });

      const useTeibanAll = state.centersIndex == 0;
      const datas = [...state.datas];
      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setTokuisaki(state: State, action: PayloadAction<{ indexs: { dataIndex: number, detailDataIndex: number }[], tokuisaki?: CodeName, direct?: boolean }>) {
      const indexs = action.payload.indexs;
      const tokuisaki = action.payload.tokuisaki;
      const direct = action.payload.direct;
      console.log('store.setTokuisaki');

      indexs.sort((a, b) => {
        if (b.dataIndex == a.dataIndex) {
          return b.detailDataIndex - a.detailDataIndex;
        }
        return b.dataIndex - a.dataIndex;
      });
      indexs.forEach(index => {
        const dataIndex = index.dataIndex;
        const detailDataIndex = index.detailDataIndex;

        let detail = state.datas[dataIndex].details[detailDataIndex];
        const suffix = "ｻﾏﾁｮｸｿｳ";
        if (tokuisaki.code === 'clear') {
          delete detail.TCD;
          delete detail.TNM;
          delete detail.TKN;
          delete detail.TDR;
          detail.ONT = '';
        } else {
          const name = (tokuisaki?.katakana2 ? tokuisaki.katakana2.trim() : '') + (tokuisaki?.katakana ? tokuisaki.katakana.trim() : '');
          detail = {
            ...detail,
            ...{
              TCD: tokuisaki.code,
              TNM: tokuisaki.name,
              TKN: tokuisaki.katakana.trim(),
              TDR: direct,
              ONT: direct ? name.substring(0, 20 - suffix.length) + suffix : null,
            }
          };
        }
        state.datas[dataIndex].details[detailDataIndex] = detail;
        state.datas[dataIndex].details = calcDetails(state.datas[dataIndex].details);
      });

      const useTeibanAll = state.centersIndex == 0;
      const datas = [...state.datas];
      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    insertRow(state:State, action: PayloadAction<{ ICDs: string[] }>) {
      console.log('store.insertRow');
      const ICDs = action.payload.ICDs;

      let newDatas:RowData[] = [];
      ICDs.forEach(ICD => {
        const data: RowData = {};
        data.ICD = ICD;
        newDatas.push(data);
      });

      const useTeibanAll = state.centersIndex == 0;
      newDatas = fillDatasDetailsCenter(newDatas, state.centers, useTeibanAll); //センター情報を埋める
      newDatas = calcDatas(newDatas); //計算項目の計算
      newDatas = checkDatas(newDatas); //データチェック
      // newDatas = doSort(newDatas, sort.key, sort.asc, false);  //ソート

      const datas = [...state.datas, ...newDatas];
      // datas = resetRowNo(datas, null); //列番号の振りなおし

      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    removeEmptyRow(state) {
      console.log('store.removeEmptyRow');
      let datas = [...state.datas];
      datas = datas.filter(data => !(!data.ICD));

      // datas = resetRowNo(datas, null); //列番号の振りなおし

      //0行になる場合は1行足す
      if(datas.length == 0) {
        const rowCount = 1;
        let newDatas:RowData[] = [];
        for(let i=0 ; i<rowCount; i++) {
          const data:RowData = {};
          newDatas.push(data);
        }

        const useTeibanAll = state.centersIndex == 0;
        newDatas = fillDatasDetailsCenter(newDatas, state.centers, useTeibanAll); //センター情報を埋める
        newDatas = calcDatas(newDatas); //計算項目の計算
        newDatas = checkDatas(newDatas); //データチェック
        // newDatas = doSort(newDatas, sort.key, sort.asc, false);  //ソート

        datas = [...datas, ...newDatas];
      }

      const useTeibanAll = state.centersIndex == 0;
      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },

    execSort(state:State, action: PayloadAction<{sort:{key:string, asc:boolean}}>) {
      console.log('store.execSort');
      const sort = action.payload.sort;
      const key = sort.key;
      const asc = sort.asc;

      const useTeibanAll = state.centersIndex == 0;

      let datas = [...state.datas];
      datas = doSort(datas, key, asc, false);
      // datas = resetRowNo(datas, null); //列番号の振りなおし

      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setDatas(state:State, action: PayloadAction<RowData[]>) {
      console.log('store.setDatas');
      let datas = action.payload;
      datas = parseData(datas);
      datas = checkDatas(datas); //データチェック

      const useTeibanAll = state.centersIndex == 0;

      const rowInfos = convertRows(datas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    editRowDatas(state:State, action: PayloadAction<{row:number, col:number, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) {
      console.log('store.editRowDatas');
      const newDatas = [...state.datas];
      // const newRows = [...state.rows];

      const editDataContent = (rowInfo:RowInfo, row:number, col:number, key:string, value:string|number|boolean|object|null) => {
        if(!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if(!key) {
          key = keyFromCol(col);
        }
        if(!(col >= 0)) {
          col = colFromKey(key);
        }
        const keyInfo = keyInfoFromCol(col);

        //parse
        const dataType = colDataType[key];
        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        let data = newDatas[rowInfo.dataIndex];

        switch (keyInfo.dataType) {
          case "no1":
          case "no2":
            break;
          case "data":
            data[key] = value;
            data = calcData(data);
            data = checkData(data);
            break;
          case "detail": {
            let detail = newDatas[rowInfo.dataIndex].details[rowInfo.detailDataIndex];
            detail[key] = value;
            detail = calcDetail(detail);
            detail = checkDetail(data, detail);
          }
            break;

          default:
            break;
        }
        newDatas[rowInfo.dataIndex].details = calcDetails(newDatas[rowInfo.dataIndex].details);
        // newRows[row][col] = value;
      };

      action.payload.forEach((editData)=>{
        const key = keyFromCol(editData.col);
        const rowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }
        editDataContent(rowInfo, editData.row, editData.col, key, editData.value);
        if(key == 'IJN') {
          let newData = newDatas[rowInfo.dataIndex];
          //削除時
          if(!editData.value) {
            clearItemInfo(newData);
          }
          //商品属性を検索する
          else {
            newData.itemInfoRetrieve = true; //item情報検索対象
            newData.ICD = '';
          }
          newData = checkData(newData);
        }
        else if(key == 'ICD') {
          let newData = newDatas[rowInfo.dataIndex];
          //削除時
          if(!editData.value) {
            clearItemInfo(newData);
          }
          else {
            let itemInfo;
            if(newData.itemInfos) {
              itemInfo = newData.itemInfos.find(itemInfo => itemInfo.ICD == editData.value);
            }
            //すでに持っているなら検索しない
            if(itemInfo) {
              newData = setItemInfo(newData, newData.itemInfos);
              const useTeibanAll = state.centersIndex == 0;
              newData = fillDataDetailsCenter(newData, state.centers, useTeibanAll); //センター情報を埋める
              newData = checkData(newData);
              newDatas[rowInfo.dataIndex] = newData;
            }
            //商品属性を検索する
            else {
              newData.itemInfoRetrieve = true; //item情報検索対象
              newData.IJN = '';
            }
          }
          newData = checkData(newData);
        }

        //関連データの更新
        if(editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            editDataContent(rowInfo, editData.row, null, relatedValue.key, relatedValue.value);
          })
        }
      })
      const useTeibanAll = state.centersIndex == 0;
      const rowInfos = convertRows(newDatas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: newDatas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setItemInfos(state:State, action: PayloadAction<{uuid:string, itemInfos:ItemInfo[]}>) {
      console.log('store.setItemInfos');
      let itemInfos: ItemInfo[] = action.payload.itemInfos;
      itemInfos = parseItemInfo(itemInfos);
      const newDatas = state.datas.map(data => {
        if(data.itemInfoRetrieve) {
          //同一ICDのデータを探し、JANコードを取得する
          let itemInfoICD_IJN = '';
          if(data.ICD) {
            const itemInfoICD = itemInfos.find(itemInfo => itemInfo.ICD == data.ICD);
            if(itemInfoICD) {
              itemInfoICD_IJN = itemInfoICD.IJN;
            }
          }
          const infos = itemInfos.filter(itemInfo => itemInfo.ICD == data.ICD || itemInfo.IJN == data.IJN || itemInfo.IJN == itemInfoICD_IJN);
          data.itemInfoRetrievedId = uuid;
          data = setItemInfo(data, infos);

          const useTeibanAll = state.centersIndex == 0;
          data = fillDataDetailsCenter(data, state.centers, useTeibanAll); //センター情報を埋める
          data = checkData(data);
          return data;

          // let itemInfoIndex = 0;
          // //すでに選択済みの場合
          // if(data.ICD) {
          //   infos.find((info, index) => {
          //     let b = info.ICD == data.ICD;
          //     if(b) {
          //       itemInfoIndex = index;
          //     }
          //     return b;
          //   });
          // }

          // return {...data, ...{
          //   itemInfoRetrieve : false,
          //   itemInfos : infos,
          //   itemInfoIndex : itemInfoIndex,
          //   //商品属性
          //   IJN: infos.length == 0 ? data.IJN : infos[itemInfoIndex].IJN,
          //   IJC: infos.length,
          //   ICD: infos.length == 0 ? data.ICD : infos[itemInfoIndex].ICD,
          //   ISC: infos.length == 0 ? null : infos[itemInfoIndex].ISC,
          //   ISN: infos.length == 0 ? null : infos[itemInfoIndex].ISN,
          //   IMC: infos.length == 0 ? null : infos[itemInfoIndex].IMC,
          //   IMN: infos.length == 0 ? null : infos[itemInfoIndex].IMN,
          //   INM: infos.length == 0 ? null : infos[itemInfoIndex].INM,
          //   ICC: infos.length == 0 ? null : infos[itemInfoIndex].ICC,
          //   ICN: infos.length == 0 ? null : infos[itemInfoIndex].ICN,
          //   IBC: infos.length == 0 ? null : infos[itemInfoIndex].IBC,
          //   IBN: infos.length == 0 ? null : infos[itemInfoIndex].IBN,
          //   ICP: infos.length == 0 ? null : infos[itemInfoIndex].ICP,
          //   IC1: infos.length == 0 ? null : infos[itemInfoIndex].IC1,
          //   IC2: infos.length == 0 ? null : infos[itemInfoIndex].IC2,
          //   ILI: infos.length == 0 ? null : infos[itemInfoIndex].ILI,
          //   IPR: infos.length == 0 ? null : infos[itemInfoIndex].IPR,
          // }};
        }
        return data;
      });

      const useTeibanAll = state.centersIndex == 0;
      const rowInfos = convertRows(newDatas, state.centers, useTeibanAll);
      Object.assign(state, {
        datas: newDatas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    // setRowDatas(state:State, action: PayloadAction<RowData[]>) {
    //   state.rows = action.payload;
    // },
    // rowSelectionChange(state:State, action: PayloadAction<{start:number,end:number}>){
    //   console.log('store.rowSelectionChange');
    //   state.selectionRowStart = action.payload.start;
    //   state.selectionRowEnd = action.payload.end;
    // },
    refreshTable(state){
      console.log('store.refreshTable');
      const useTeibanAll = state.centersIndex == 0;
      const rowInfos = convertRows(state.datas, state.centers, useTeibanAll);
      Object.assign(state, {
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setBeforeChange(state: State, action: PayloadAction<{ changes: Handsontable.CellChange[], source: Handsontable.ChangeSource }>) {
      state.beforeChange = action.payload;
    },
    setInitList(state:State, action: PayloadAction<string>) {
      if (state.initList.some(data => data == action.payload)) {
        state.initList = state.initList.filter(data => data != action.payload);
      }
    },
    setErrorMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setErrorMessage');
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setInfoMessage');
      state.infoMessage = action.payload;
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas:RowData[]): RowData[] => {
  //set No.
  datas.forEach((data) => {
    if(typeof data.IC1 === 'string') data.IC1 = parseInt(data.IC1);
    if(typeof data.IC2 === 'string') data.IC2 = parseInt(data.IC2);
    if(typeof data.ILI === 'string') data.ILI = parseInt(data.ILI);
    if(typeof data.IPR === 'string') data.IPR = parseInt(data.IPR);
    //明細
    if(data.details) {
      data.details.forEach((data) => {
        if(typeof data.HPL === 'string') data.HPL = parseInt(data.HPL);
        if(typeof data.OOS === 'string') data.OOS = data.OOS == 'true';
        if(typeof data.OON === 'string') data.OON = parseInt(data.OON);
        if(typeof data.OOZ === 'string') data.OOZ = parseInt(data.OOZ);
        if(typeof data.OFN === 'string') data.OFN = parseInt(data.OFN);
        if(typeof data.OAN === 'string') data.OAN = parseInt(data.OAN);
        if(typeof data.OLT === 'string') data.OLT = parseInt(data.OLT);

        if(typeof data.ZQ1 === 'string') data.ZQ1 = parseFloat(data.ZQ1);
        if(typeof data.ZQ2 === 'string') data.ZQ2 = parseFloat(data.ZQ2);
        if(typeof data.ZQ3 === 'string') data.ZQ3 = parseFloat(data.ZQ3);
      });
    }

  });
  return datas;
}

const parseItemInfo = (datas:ItemInfo[]): ItemInfo[] => {
  datas.forEach((data) => {
    if(typeof data.IC1 === 'string') data.IC1 = parseInt(data.IC1);
    if(typeof data.IC2 === 'string') data.IC2 = parseInt(data.IC2);
    if(typeof data.ILI === 'string') data.ILI = parseInt(data.ILI);
    if(typeof data.IPR === 'string') data.IPR = parseInt(data.IPR);
    //定番マスタ
    if(data.teibanInfos) {
      data.teibanInfos.forEach((data) => {
        if(typeof data.HLOT === 'string') data.HLOT = parseInt(data.HLOT);
        if(typeof data.TTAN === 'string') data.TTAN = parseInt(data.TTAN);
        if(typeof data.TSLE === 'string') data.TSLE = parseInt(data.TSLE);
        if(typeof data.SDAT === 'string') data.SDAT = parseInt(data.SDAT);
        if(typeof data.EDAT === 'string') data.EDAT = parseInt(data.EDAT);
        if(typeof data.OLT === 'string') data.OLT = parseInt(data.OLT);

        if(typeof data.ZQ1 === 'string') data.ZQ1 = parseFloat(data.ZQ1);
        if(typeof data.ZQ2 === 'string') data.ZQ2 = parseFloat(data.ZQ2);
        if(typeof data.ZQ3 === 'string') data.ZQ3 = parseFloat(data.ZQ3);
      });
    }
  });
  return datas;
}

//行データにセンター情報を埋める
const fillDatasDetailsCenter = (datas:RowData[], centers:CodeName[], useTeibanAll:boolean): RowData[] => {
  console.log('fillDatasDetailsCenter');
  return datas.map(data => fillDataDetailsCenter(data, centers, useTeibanAll));
}
const fillDataDetailsCenter = (data:RowData, centers:CodeName[], useTeibanAll:boolean): RowData => {
  console.log('fillDataDetailsCenter');
  if(!useTeibanAll && (!centers || centers.length == 0)){
    return data;
  }

  const details:RowDataDetail[] = !data.details ? [] : [...data.details];
  if(!useTeibanAll) {
    //指定センターから
    centers.forEach(center => {
      let detail:RowDataDetail = details.find(detail =>
        detail.SCD == center.code
        );
      if(!detail) {
        detail = {
          SCD: center.code,
          SNM: center.name,
        };
        details.push(detail);
      }
    });
  }
  else {
    //定番マスタから
    if(data.itemInfos && data.itemInfos[data.itemInfoIndex] && data.itemInfos[data.itemInfoIndex].teibanInfos) {
      data.itemInfos[data.itemInfoIndex].teibanInfos.forEach(teibanInfo => {
        let detail:RowDataDetail = details.find(detail =>
          detail.SCD == teibanInfo.SCD
          );
        if(!detail) {
          detail = {
            SCD: teibanInfo.SCD,
            SNM: teibanInfo.SNM,
          };
          details.push(detail);
        }
      });
    }
  }
  details.sort((a, b) => compareUtil.compareString(a.OSCD ? a.OSCD : a.SCD, b.OSCD ? b.OSCD : b.SCD, true));
  data.details = calcDetails(details);
  setTeibanInfo(data);

  return data;
}
//計算
const calcDatas = (datas:RowData[]): RowData[] => {
  return datas.map(data => calcData(data));
}
const calcData = (data:RowData): RowData => {
  //TODO 計算項目



  //明細
  if(data.details) {
    data.details = data.details.map(detail => calcDetail(detail));
  }

  return data;

}
const calcDetail = (detail:RowDataDetail): RowDataDetail => {
  //TODO 計算項目

  if (detail.OOS === true) {
    detail.OFN = null;
    detail.OAN = null;
  } else {
    // 初回準備数(A+B)=「送込数(A)」+「送込以降の必要在庫数(B)」
    detail.OFN = calcUtil.plus((!detail.OON ? 0 : detail.OON), (!detail.OOZ ? 0 : detail.OOZ));
    //追加発注予測数 =「販売計画」-「初回準備数(A+B)」
    detail.OAN = calcUtil.minus2(detail.HPL, (!detail.OFN ? 0 : detail.OFN));
  }

    return detail;
}

//チェック
const checkDatas = (datas:RowData[]): RowData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data));
}

const checkData = (data:RowData): RowData => {
  const errorInfo:ErrorInfoData = {};
  if(!data.errorInfo){
    data.errorInfo = {};
  }

  //明細
  if(data.details) {
    data.details = data.details.map(detail => checkDetail(data, detail));
  }

  //TODO チェック項目
  if(!data.itemInfoRetrieve && data.itemInfoRetrievedId && data.itemInfos.length == 0) {
    errorInfo.IJN = '商品マスタに存在しません';
    errorInfo.ICD = '商品マスタに存在しません';
  }

  data.errorInfo = errorInfo;

  //編集状態をセットする
  data.edited = !isEmptyRowData(data);

  return data;
}

const checkDetail = (data:RowData, detail:RowDataDetail): RowDataDetail => {
  const errorInfo:ErrorInfoDetail = {};
  const warnInfo:WarnInfoDetail = {};

  const checkDate = (input: string, offset: number): boolean => {
    let inputDate = moment(input).startOf('day');
    if (!inputDate.isValid()) {
      inputDate = moment('20' + input).startOf('day');
    }
    const chk = moment().add(offset, 'day').startOf('day');
    return (inputDate.diff(chk, 'day') >= 0);
  }
  //TODO チェック項目
  if(data.ICD && !data.errorInfo.ICD) {
    if(!detail.teibanInfo) {
      errorInfo.SCD = '定番マスタがありません';
    }
    else if(!detail.SCD) {
      errorInfo.SCD = '倉庫が指定されていません';
    }
    else {
      const empty = isEmptyRowDataDetail(detail);
      detail.ONOA = null;  //余りBL数
      detail.ONOB = null;  //余りBL数
      if(!empty) {

        //KFR:開始日
        if (!detail.KFR) {
          if (!detail.OOS && (detail.KTO || detail.OOZ)) {
            errorInfo.KFR = '入力してください'; //期間特売では、開始日は必須
          }
        }
        else if (detail.KFR && !moment(detail.KFR).isValid() && !moment('20' + detail.KFR).isValid()) {
          errorInfo.KFR = '日付が不正';
        }
        else if (!detail.OOS && !checkDate(detail.KFR, 1)) {
          errorInfo.KFR = '翌日以降を指定してください';
        }

        //KTO:終了日
        if (!detail.KTO) {
          if (!detail.OOS && (detail.KFR || detail.OOZ)) {
            errorInfo.KTO = '入力してください'; //期間特売では、終了日は必須
          }
        }
        else if (detail.KTO && !moment(detail.KTO).isValid() && !moment('20' + detail.KTO).isValid()) {
          errorInfo.KTO = '日付が不正';
        }
        else if (!detail.OOS && !checkDate(detail.KTO, 1)) {
          errorInfo.KTO = '翌日以降を指定してください';
        }

        if (!errorInfo.KFR && !errorInfo.KTO && detail.KFR && detail.KTO) {
          // 特売開始日＞発注期限日(B)
          const kfr = moment('20' + detail.KFR).valueOf();
          const kto = moment('20' + detail.KTO).valueOf();
          if (kto < kfr) {
            errorInfo.KFR = '特売開始日≦特売終了日を指定してください';
            errorInfo.KTO = '特売開始日≦特売終了日を指定してください';
          }
        }

        //OFS:出荷日
        if (!detail.OFS) {
          if (detail.OND || detail.OON) {
            errorInfo.OFS = '入力してください'; //納品日または、送込BL数(A)が入力済み
          }
        }
        else {
          if (!moment(detail.OFS).isValid() && !moment('20' + detail.OFS).isValid()) {
            errorInfo.OFS = '日付が不正';
          }
          else if (!checkDate(detail.OFS, 0)) {
            errorInfo.OFS = '当日以降を指定してください';
          }
          else if (data.ISD || data.IED) {
            let tmp = null;
            const ofs = (tmp = moment(detail.OFS)).isValid() ? tmp : moment('20' + detail.OFS);
            const isd = (tmp = moment(data.ISD)).isValid() ? tmp : moment('20' + data.ISD);
            const ied = (tmp = moment(data.IED)).isValid() ? tmp : moment('20' + data.IED);
            if (data.ISD && isd.diff(ofs, 'day') >= 0) {
              warnInfo.OFS = '出荷日は発売日後を指定してください';
            }
            else if (data.IED && ofs.diff(ied, 'day') >= 0) {
              warnInfo.OFS = '出荷日は終売日前を指定してください';
            }
          }
        }

        //OND:納品日
        if (!detail.OND) {
          if (detail.OFS || detail.OON) {
            errorInfo.OND = '入力してください'; //出荷日または、送込BL数(A)が入力済み
          }
        }
        else {
          if (!moment(detail.OND).isValid() && !moment('20' + detail.OND).isValid()) {
            errorInfo.OND = '日付が不正';
          }
          else if (!checkDate(detail.OND, 0)) {
            errorInfo.OND = '当日以降を指定してください';
          }
        }

        if (!errorInfo.OFS && !errorInfo.OND) {
          // 初回出荷日＞納品日
          let tmp = null;
          const ofs = (tmp = moment(detail.OFS)).isValid() ? tmp : moment('20' + detail.OFS);
          const ond = (tmp = moment(detail.OND)).isValid() ? tmp : moment('20' + detail.OND);
          if (ofs.diff(ond, 'day') > 0) {
            errorInfo.OFS = '出荷日は納品日以前を指定してください';
            errorInfo.OND = '納品日は出荷日以降を指定してください';
          }
        }
        if ((errorInfo.OFS || errorInfo.OND) && !detail.OON) {
          errorInfo.OON = '入力してください'; //出荷日または、納品日が入力済み
        }

        //OLDA:発注期限日(A)
        if(!detail.OLDA) {
          if(detail.OON) {
            // errorInfo.OLDA = '入力してください'; //必須にはしない
          }
        }
        else if(!moment(detail.OLDA).isValid() && !moment('20' + detail.OLDA).isValid()) {
          errorInfo.OLDA = '日付が不正';
        }
        else if (!checkDate(detail.OLDA, 0)) {
          errorInfo.OLDA = '当日以降を指定してください';
        }
        // if (!errorInfo.OFS && !errorInfo.OLDA) {
        //   // 初回出荷日＞発注期限日(A)
        //   const ofs = moment('20' + detail.OFS).valueOf();
        //   const old = moment('20' + detail.OLDA).valueOf();
        //   if (ofs < old) {
        //     errorInfo.OFS = '出荷日は発注期限日(A)以降を指定してください';
        //     errorInfo.OLDA = '発注期限日(A)は出荷日以前を指定してください';
        //   }
        // }

        //OLDB:発注期限日(B)
        if(!detail.OLDB) {
          if(detail.OOZ) {
            // errorInfo.OLDB = '入力してください'; //必須にはしない
          }
        }
        else if(!moment(detail.OLDB).isValid() && !moment('20' + detail.OLDB).isValid()) {
          errorInfo.OLDB = '日付が不正';
        }
        else if (!checkDate(detail.OLDB, 0)) {
          errorInfo.OLDB = '当日以降を指定してください';
        }
        // if (!errorInfo.KFR && !errorInfo.OLDB) {
        //   // 特売開始日＞発注期限日(B)
        //   const kfr = moment('20' + detail.KFR).valueOf();
        //   const old = moment('20' + detail.OLDB).valueOf();
        //   if (kfr < old) {
        //     errorInfo.KFR = '特売開始日は発注期限日(B)以降を指定してください';
        //     errorInfo.OLDB = '発注期限日(B)は特売開始日以前を指定してください';
        //   }
        // }

        //HPL:販売計画BL数
        if (!detail.HPL && !detail.OOS) {
          errorInfo.HPL = '入力してください'; //期間特売では、販売計画BL数は必須
        }
        else if (detail.HPL) {
          if(typeof detail.HPL != 'number' || detail.HPL < 0) {
            errorInfo.HPL = '数値が不正';
          }
          const bl = (!detail.OON ? 0 : detail.OON) + (!detail.OOZ ? 0 : detail.OOZ);
          if (bl > detail.HPL) {
            errorInfo.HPL = '初回準備BL数未満です';
          }
        }

        //OOU:入庫単位
        if(!detail.OOU) {
          errorInfo.OOU = '選択してください';
        }
        else if (['CS', 'BL'].indexOf(detail.OOU) === -1) {
          errorInfo.OOU = 'CS、BLから選択してください';
        }
        else if (detail.OOU == 'CS') {
          if (detail.OON && detail.OON % (data.IC2 * data.ITN) != 0) {
            detail.ONOA = data.IC2 * data.ITN - detail.OON % (data.IC2 * data.ITN);  //余りBL数
            warnInfo.OON = '発注CS数に満たないため、発注時に余りが発生します';
          }
          if (detail.OOZ && detail.OOZ % (data.IC2 * data.ITN) != 0) {
            detail.ONOB = data.IC2 * data.ITN - detail.OOZ % (data.IC2 * data.ITN);  //余りBL数
            warnInfo.OOZ = '発注CS数に満たないため、発注時に余りが発生します';
          }
        }

        //OOS:スポット
        if (detail.OOS === true) {
          if (!detail.OON) {
            errorInfo.OON = '入力してください'; //スポットでは、送込BL数(A)は必須
          }
          if (!detail.OFS) {
            errorInfo.OFS = '入力してください'; //スポットでは、出荷日は必須
          }
          if (!detail.OND) {
            errorInfo.OND = '入力してください'; //スポットでは、納品日は必須
          }
        }
        else {
          if ((detail.KFR || detail.KTO) && !detail.OOZ) {
            errorInfo.OOZ = '入力してください'; //期間特売では、送込以降の必要BL数(B)は必須
          }
        }

        if(detail.OON && (typeof detail.OON != 'number' || detail.OON < 0)) {
          errorInfo.OON = '数値が不正';
        }
        if(detail.OOZ && (typeof detail.OOZ != 'number' || detail.OOZ < 0)) {
          errorInfo.OOZ = '数値が不正';
        }
        if (detail.ONT) {
          if (detail.ONT.length > 20) {
            errorInfo.ONT = '20字まで入力可能です';
          } else if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~｡-ﾟ ]*$/.test(detail.ONT)) {
            errorInfo.ONT = '半角のみ入力可能です';
          }
        }
      }

      if ((!detail.ZTN || detail.ZTN.trim().length <= 0)) {
        warnInfo.ZTN = '棚番がありません';
      }
    }

  }

  detail.errorInfo = errorInfo;
  detail.warnInfo = warnInfo;

  //編集状態をセットする
  detail.edited = !isEmptyRowDataDetail(detail);

  return detail;
}
const calcDetails = (details: RowDataDetail[]): RowDataDetail[] => {
  if (!details || details.length === 0) {
    return details;
  }
  const tmpDetails = details.map((detail, index) => { return { index: index, SCD: detail.SCD, OSCD: detail.OSCD, TCD: detail.TCD, TDR: detail.TDR, } });
  details.forEach((detail, index) => {
    const no = tmpDetails.filter(d => d.SCD == detail.SCD && d.OSCD == detail.OSCD && d.TCD == detail.TCD && d.TDR == detail.TDR)
      .map(d => d.index)
      .findIndex(i => i == index) + 1;
    detail.SKEY =
      (detail.OSCD && detail.SCD != detail.OSCD ? detail.OSCD : detail.SCD) +
      '_' + detail.SCD +
      '_' + no +
      (detail.TCD ? '_' + detail.TCD : '') +
      (detail.TCD ? '_' + detail.TDR : '');
  });
  return details;
}
//no振りなおし
//no振りなおし
// const resetRowNo = (datas:RowInfo[]): RowData[] => {
//   //set No.
//   datas = datas.map((row, index) => {
//     return {
//       ...row,
//       no: "" + (index+1),
//     }
//   });
//   return datas;
// }

//配列データに変換
const convertRows = (datas:RowData[], centers:CodeName[], useTeibanAll:boolean): {
  rows:[][],
  // dataRowIndexes:number[],
  rowInfos: RowInfo[],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]
} => {
  console.log('store.convertRows');

  if(!centers || centers.length == 0) {
    centers = [new CodeName({code:'', name:''})];
  }

  const mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  const mergeCols = [];
  //固定列すべてマージは崩れる
  colKeysNo_data.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey));
  });
  colKeysData_fix.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey));
  });
  colKeysData_unfix.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey));
  });

  const rows = [];
  // let dataRowIndexes = [];
  const rowInfos:RowInfo[] = [];
  //set No.
  datas.forEach((data, index1) => {

    //一時的に全ての表示状態をfalseにする
    data.visible = false;

    const s = rows.length;
    const no1:string = "" + (index1 + 1);
    let detailCount = 0;

    const detailRowFunc = (detail, detailDataIndex) => {
      detailCount++;
      const no2:string = "" + detailCount;
      const no:string = no1 + '-' + no2;

      const r = [];
      [...colKeysNo_data, ...colKeysNo_detail].forEach((colKey) => {
        switch (colKey) {
          case 'no1':
            r.push(no1); //No.
            break;
          case 'no2':
            r.push(no2); //No.
            break;
          default:
            r.push(null);
            break;
        }
      });
      colKeysData_fix.forEach((colKey) => {
        r.push(data[colKey]);
      });
      colKeysDetail_fix.forEach((colKey) => {
        r.push(detail ? detail[colKey] : null);
      });
      colKeysData_unfix.forEach((colKey) => {
        r.push(data[colKey]);
      });
      colKeysDetail_unfix.forEach((colKey) => {
        r.push(detail ? detail[colKey] : null);
      });

      rows.push(r);
      // dataRowIndexes.push(index);
      rowInfos.push({
        no: no,
        row:rowInfos.length,
        data: data,
        dataIndex:index1,
        detail: detail,
        detailDataIndex:detailDataIndex,
        detailRowIndex:detailCount - 1,
        detailRowEnd: false,  //一時的に
      });
      if(data) {
        data.visible = true;
      }
      if(detail) {
        detail.visible = true;
      }
    };

    //一時的に全ての表示状態をfalseにする
    if(data && data.details) {
      data.details.filter((detail, index2) => {
        detail.visible = false;

        {
          //定番マスタがあれば表示する
          if(detail.teibanInfo) {
            if(useTeibanAll) {
              detailRowFunc(detail, index2);
            }
            else {
              //表示対象センターならば表示する
              const center = centers.find(center => detail.SCD == center.code);
              if(center) {
                detailRowFunc(detail, index2);
              }
            }
          }
        }
      });
    }

    // centers.forEach((center, index2) => {
    //   let detailDataIndex = -1;
    //   let detail = data.details ? data.details.find((detail, index) => {
    //     if(detail.SCD == center.code) {
    //       detailDataIndex = index;
    //       return true;
    //     }
    //     return false;
    //   }) : null;
    //   //定番マスタがあれば表示する
    //   if(!detail || detail.teibanInfo) {
    //     detailRowFunc(detail, detailDataIndex);
    //   }
    // });
    //0件は空データを投入
    if(detailCount == 0) {
      detailRowFunc(null, -1);
    }

    rowInfos[rowInfos.length-1].detailRowEnd = true;

    //マージ
    const e = rows.length;
    if(e - s > 1) {
      const rowspan = e - s;
      mergeCols.forEach(col => {
        mergeCells.push({row: s, col: col, rowspan: rowspan, colspan: 1});
      });
    }
  });

  return {
    rows:rows,
    // dataRowIndexes:dataRowIndexes,
    rowInfos:rowInfos,
    mergeCells: mergeCells,
  };
}

//ソート
const doSort = (datas:RowData[], colKey:string, asc:boolean, itemCDSort:boolean): RowData[] => {
  datas.sort((a, b) => {
    const objA = a;
    const objB = b;
    //アイテムCDでソート
    if(itemCDSort) {
      let comp = 0;
      comp = compareUtil.compareString(objA ? objA.ICD : null, objB ? objB.ICD : null, asc);
      if(comp != 0) {
        return comp;
      }
    }

    let comp = 0;
    //ICD違い
    if(a.ICD != b.ICD){
      //何もしない
    }
    //ICD同じ
    else {
      const va = objA ? objA[colKey] : null;
      const vb = objB ? objB[colKey] : null;
    //数値型
      if(typeof va === 'number' || typeof vb === 'number') {
        comp = compareUtil.compareNumber(va, vb, asc);
      }
      else if(typeof va === 'string' || typeof vb === 'string') {
        comp = compareUtil.compareString(va, vb, asc);
      }
      else if((typeof va === 'object' && va instanceof Date) || (typeof vb === 'object' && vb instanceof Date)) {
        comp = compareUtil.compareDate(va, vb, asc);
      }
    }

    return comp;
  });

  return datas;

}

export const lastUpdateZaiko = (rowInfos: RowInfo[]): string => {
  const map = rowInfos.filter(rowInfo => rowInfo.detail && rowInfo.detail.DATEUP && rowInfo.detail.TIMEUP)
    .map(rowInfo => moment(rowInfo.detail.DATEUP.padStart(8, '0') + '' + rowInfo.detail.TIMEUP.padStart(6, '0'), 'YYYYMMDDHHmmss'));
  if (map.length == 0) {
    return "";
  }

  const max: moment.Moment = map.reduce((a, b) => a.unix() >= b.unix() ? a : b);
  const now = moment(new Date());
  if (now.diff(max, 'day')) {
    return ' (' + max.format('M/D H') + '時時点)';
  }
  return ' (' + max.format('H') + '時時点)';
}